export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/(landing)": [6,[2],[3]],
		"/app": [18,[4]],
		"/app/auth/callback": [19,[4]],
		"/app/collections": [21,[4]],
		"/app/collection/[id]": [~20,[4]],
		"/app/search": [22,[4]],
		"/app/sync-status": [23,[5]],
		"/app/trash": [24,[4]],
		"/(landing)/changelog": [7,[2],[3]],
		"/(landing)/extension": [8,[2],[3]],
		"/(landing)/growth": [9,[2],[3]],
		"/(landing)/home": [10,[2],[3]],
		"/(landing)/login": [11,[2],[3]],
		"/(landing)/pricing": [12,[2],[3]],
		"/(landing)/privacy-policy": [13,[2],[3]],
		"/(landing)/roadmap": [14,[2],[3]],
		"/(landing)/signup": [15,[2],[3]],
		"/(landing)/terms-of-service": [16,[2],[3]],
		"/(landing)/zen": [17,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';